<!--公共模块--数据比较趋势列表-->
<template>
  <div
    id="dataCompareTrendList"
    v-loading="loading"
    element-loading-text="$t('dataCompareTrendList.a1')"
    ref="dataCompareTrendList"
  >      
    <div class="main">
      <div class="main-all-vessel">
        <a-table
          :columns="columns"
          :dataSource="dataList"          
          :pagination="pagination"
        >
        </a-table>
      </div>      
    </div>
    <div class="footer">
      <a-button type="primary" style="width: 120px"  @click="output" >{{$t('dataCompareTrendList.a2')}}</a-button>
      <a-button type="primary" style="width: 120px"  @click="reload" >{{$t('dataCompareTrendList.a3')}}</a-button>
    </div>  
  </div>      
</template>

<script>
import tabletitle from "../../../components/Title/tabletitle";
import {getTrendData} from "../../../api/device";
import moment from 'moment';
import { downloadCSVUrl } from "../../../utils/utils";

export default {
  props:{
    signalPoint:{},
    dates:[],
    div:"",
  },
  data() {
    return {
      // signalPoint:this.$route.query.signalPoint,
      // dates:this.$route.query.dates,
      loading: true,
      dataList: [],
      dateString:this.$t('dataCompareTrendList.a4'),
      pagination: {
        total: 0, //数据总数
        pageSize: 10, //每页中显示10条数据
        showSizeChanger: true, //是否可以改变pagesize
        pageSizeOptions: ["5", "10", "50", "100"], //每页中显示的数据
        showTotal: (total) => ` ${total + this.$t('dataCompareTrendList.a5')}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: true, //只有一页时是否隐藏分页：默认值false
      },
      columns: [
        {          
          title: 'TagPoint',
          width: 80,
          children: [
            {
              title: this.$t('dataCompareTrendList.a6'),
              width: 80,
              children: [
                {
                  title: this.$t('dataCompareTrendList.a7'),
                  width: 80,
                  children: [
                    {
                      title: this.$t('dataCompareTrendList.a8'),
                      width: 80,
                      children: [
                        {
                          title: this.$t('dataCompareTrendList.a9'),
                          width: 80,
                          children: [
                            {
                              title: this.$t('dataCompareTrendList.a10'),
                              dataIndex: "time",
                              // scopedSlots: { customRender: 'time' },   
                              width: 80,
                            },            
                          ],                          
                        },            
                      ],                      
                    },            
                  ],                  
                },            
              ],              
            },            
          ],               
        },             
      ],
    };
  },
  mounted() {    
    this.showsignalPoints();
    this.getData(); 
  },
   watch: {    
  },
  methods: {   
    showsignalPoints(){
      // console.log("dataCompareTrendList signalPoint:",this.signalPoint);
      // console.log("dataCompareTrendList dates:",this.dates);
      if(this.signalPoint!=null){        
        let column =
        {            
          title: this.signalPoint.tagPoint,
          children: [
            {
              title: this.signalPoint.signalTypeDesc,
              children: [
                {
                  title: this.signalPoint.dvName,
                  children: [
                    {
                      title: this.signalPoint.signalName,
                      children: [
                        {
                          title: this.$t('dataCompareTrendList.a11'),
                          children: [
                            {
                              title: this.dates[0],
                              dataIndex: "value1",
                              width: 80,
                            },
                            {
                              title: this.dates[1],
                              dataIndex: "value2",
                              width: 80,
                            },
                            {
                              title: this.dates[2],
                              dataIndex: "value3",
                              width: 80,
                            },
                            {
                              title: this.dates[3],
                              dataIndex: "value4",
                              width: 80,
                            },
                            {
                              title: this.dates[4],
                              dataIndex: "value5",
                              width: 80,
                            },
                            {
                              title: this.dates[5],
                              dataIndex: "value6",
                              width: 80,
                            },
                          ],
                          width: 80,
                        },
                      ],
                      width: 200,
                    }, 
                  ],                    
                  width: 200,
                }, 
              ],
              width: 200,
            }, 
          ],
          width: 200,
        }
        this.columns.push(column);
      }
    },
    output(){
      let fileName = this.$t('dataCompareTrend.a1')+'_'+moment().format('YYYY-MM-DD HH:mm:ss');
      this.JSONToCSVConvertor(fileName);
    },
    /**
   * 导出CSV
   * @param {Object} fileName  文件名称
   */
    JSONToCSVConvertor(fileName) {
      var CSV = '';
      var row = "";
      row = this.$t('dataCompareTrendList.a6');      
      for (var i = 0; i < this.dates.length; i++) {        
        row += ','+this.dates[i];
      }      
      CSV += row + '\r\n';    
      for (var i = 0; i < this.dataList.length; i++) {        
        let trendData=this.dataList[i];        
        row = trendData.time;
        for (var j = 0; j < this.dates.length; j++) {  
          if(j==0)          
            row += ','+trendData.value1;
          if(j==1)
            row += ','+trendData.value2;
          if(j==2)
            row += ','+trendData.value3;
          if(j==3)
            row += ','+trendData.value4;
          if(j==4)
            row += ','+trendData.value5;
          if(j==5)
            row += ','+trendData.value6;
        }
        CSV += row + '\r\n'; 
      }    
      
      if (CSV == '') {
        alert("Invalid data");
        return;
      }      
      downloadCSVUrl(CSV,fileName);
    },

    reload(){//重新加载数据
      this.getData();  
    },         
    getData(){
      let dateString = this.dates?this.dates.join(','):''; 
      let tagPoints="";
      let tagPoint = "";
      this.loading = true;
      console.log("getTrendData dateString:",dateString);
      if(this.signalPoint!=null){
          tagPoint = this.signalPoint.signalNo+"@@"+this.signalPoint.signalKind+"@@"+this.signalPoint.signalType;
          if(tagPoints=="")
            tagPoints = tagPoint;
          else
            tagPoints = tagPoints+","+tagPoint;        
      }
      
      let par = {
        tagPoints: tagPoints,
        dates: dateString,
        div:this.div,
      };

      console.log("getTrendData par",par);
      getTrendData(par).then((res) => {
            let { data } = res;
            this.dataList = data;
            if(this.dataList!=null&&this.dataList.length>0){
              for(let i=0;i<this.dataList.length;i++){
                this.dataList[i].key=i;
              }
            }
            console.log("getTrendData：",this.dataList);              
          })
          .catch((err) => {
            console.log(err);
          });
      this.loading = false;
    }   
  },
  components: {
    "my-tabletitle": tabletitle,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#dataCompareTrendList {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow: auto;
}
.header {
  height: 60px;
  padding: 10px 20px 0px 20px;
}
.inquire {
  height: 50px;
  padding: 0px 20px;
  border-bottom: 1px solid rgba(12, 12, 12, 0.1);
  white-space: nowrap;
}
.main {
  width: 100%;
  height: calc(100% - 113px);
  padding: 10px 20px;
  overflow: auto;
}
.main-all-vessel {
  overflow: auto;
}
.form_row {
  display: flex;
  align-items: flex-end;
}
.form_col {
  margin-right: 15px;
  min-width: 1px;
  white-space: nowrap;
}
.footer {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 20px;
  overflow: hidden;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
}
.footer button {
  margin-left: 10px;
}
.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.margin {
  margin-right: 10px;
}
p {
  margin-bottom: 10px;
}
</style>